<template>
	<section v-if="reviews.length" class="main-content">
		<div class="row small-row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 review-slider">
				<client-only>
					<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
						<slide v-for="review in reviews" :key="review.ID">
							<div class="review-slider-content">
								<p>{{ review.content }}</p>
								<div class="source">
									<span class="source-logo" :class="review.source" />
									<div>
										<h4>{{ review.firstName }}</h4>
										<p>
											<span v-if="review.date">
												{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
											</span>
										</p>
									</div>
								</div>
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.review-slider {
	padding: 0 200px;
	overflow: hidden;

	.review-slider-content {
		text-align: center;

		hr {
			margin: 25px 0;
			opacity: 0.2;
		}

		.source {
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			margin: 20px 0;
			gap: 10px;

			div {
				text-align: left;
			}

			span,
			strong {
				float: left;
				margin: 0 4px 0 0;
			}

			h4,
			p {
				margin: 0;
			}

			.source-logo {
				display: inline-block;
				width: 50px;
				height: 50px;
				background-size: 50px 50px;
				margin: 5px 0 0 10px;

				&.tripadvisor {
					background-image: url('~/assets/images/review-logo-tripadvisor.png');
				}

				&.bookingcom {
					background-image: url('~/assets/images/review-logo-bookingcom.png');
				}

				&.zoover {
					background-image: url('~/assets/images/review-logo-zoover.png');
				}

				&.expedia {
					background-image: url('~/assets/images/review-logo-expedia.png');
				}

				&.google {
					background-image: url('~/assets/images/review-logo-google.png');
				}
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__pagination-item) {
	margin: 0 4px;

	button {
		width: 11px;
		height: 11px;
		border: 1px solid #fff;
		border-radius: 50%;
		background: rgb(239 239 239);

		&.carousel__pagination-button--active {
			background: #222;
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;

		&::before {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;

		.review-slider-content p .review-source {
			display: block;
			float: right;
			margin: 40px 0 0 20px;
		}

		.review-slider-content .source .source-logo {
			margin: 20px 0 0;
		}
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
